import React, { useState } from "react";
import Breadcrumbs from "common/Breadcrumbs/Breadcrumbs";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { RootState } from "components/App/store";
import ManagePasswordTab from "components/ManagePasswordTab/ManagePasswordTab";
import OrderHistoryTab from "components/OrderHistoryTab/OrderHistoryTab";
import ProfileTab from "components/ProfileTab/ProfileTab";
import Modal from "common/Modal/Modal"; // Import your modal component
import { routeConstants } from "navigation";
import { useSelector } from "react-redux";
import { ReactComponent as AvatarIcon } from "common/assets/icons/user.svg";
import { useNavigate } from "react-router-dom";
import { serviceConfig } from "configs";
import { deleteCookie } from "utils/cookie";

const AccountPage: React.FC = () => {
	const auth = useSelector((state: RootState) => state.auth);
	const [activeTab, setActiveTab] = useState<string>("Profile");
	const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
	const navigate = useNavigate();

	const breadcrumbItems = [
		{ label: "Home", path: routeConstants.root.route },
		{ label: activeTab, path: "noRoute" },
	];

	const handleLogout = () => {
		deleteCookie("token");
		deleteCookie("user");
		navigate(routeConstants.root.route);
		window.location.reload();
	};

	return (
		<div className="px-[80px] TwoXl:px-[90px] ThreeXl:px-[110px] grid grid-cols-1 grid-flow-row auto-rows-max gap-8 pb-12">
			<div className="grid grid-flow-row grid-cols-1 gap-5 py-6 border-b auto-rows-max border-strokeColor">
				<Breadcrumbs items={breadcrumbItems} whiteMode={false} />
				<div className="grid gap-5 grid-cols-maxTwo">
					{auth.user?.ProfileImage ? (
						<img
							src={`${serviceConfig.apiUrl}/get-image/${auth.user?.ProfileImage}`}
							alt="profile_image"
							className="w-16 h-16 border border-black rounded-full opacity-80"
						/>
					) : (
						<div className="p-3 border border-black rounded-full opacity-80">
							<AvatarIcon className="w-10 h-10" />
						</div>
					)}

					<div className="grid grid-flow-row grid-cols-1 gap-1 auto-rows-max">
						<Htag tag="h2" type="semibold">
							{auth.user?.FirstName || auth.user?.LastName
								? `${auth.user?.FirstName} ${auth.user?.LastName}`
								: "No Name"}
						</Htag>
						<P size="p2" type="regular" className="text-textSecondaryColor">
							{auth.user?.Email ? `${auth.user?.Email}` : "user@email.com"}
						</P>
					</div>
				</div>
			</div>
			<div className="grid grid-flow-row grid-cols-1 gap-6 auto-rows-max">
				<div className="grid gap-3 border-b grid-cols-maxFour border-strokeColor">
					<div
						className={`px-1 pb-3 ${
							activeTab === "Profile" ? "border-violet-500 border-b-2" : ""
						}`}
						onClick={() => setActiveTab("Profile")}
					>
						<P
							size="p3"
							type="semibold"
							className={`cursor-pointer ${
								activeTab === "Profile" ? "text-mainColor" : "text-buttonColor"
							}`}
						>
							Account settings
						</P>
					</div>
					<div
						className={`px-1 pb-3 ${
							activeTab === "Manage Password"
								? "border-violet-500 border-b-2"
								: ""
						}`}
						onClick={() => setActiveTab("Manage Password")}
					>
						<P
							size="p3"
							type="semibold"
							className={`cursor-pointer ${
								activeTab === "Manage Password"
									? "text-mainColor"
									: "text-buttonColor"
							}`}
						>
							Manage Password
						</P>
					</div>
					<div
						className={`px-1 pb-3 ${
							activeTab === "Order History"
								? "border-violet-500 border-b-2"
								: ""
						}`}
						onClick={() => setActiveTab("Order History")}
					>
						<P
							size="p3"
							type="semibold"
							className={`cursor-pointer ${
								activeTab === "Order History"
									? "text-mainColor"
									: "text-buttonColor"
							}`}
						>
							Order History
						</P>
					</div>
					<div
						className="px-1 pb-3 cursor-pointer"
						onClick={() => setIsLogoutModalOpen(true)}
					>
						<P
							size="p3"
							type="semibold"
							className="cursor-pointer text-redColor"
						>
							Logout
						</P>
					</div>
				</div>
				<>
					{activeTab === "Profile" && <ProfileTab />}
					{activeTab === "Manage Password" && <ManagePasswordTab />}
					{activeTab === "Order History" && <OrderHistoryTab />}
				</>
			</div>

			{/* Logout Confirmation Modal */}
			<Modal
				isOpen={isLogoutModalOpen}
				onClose={() => setIsLogoutModalOpen(false)}
				title="Are you sure you want to log out?"
				onConfirm={handleLogout}
				onCancel={() => setIsLogoutModalOpen(false)}
			/>
		</div>
	);
};

export default AccountPage;
